<template>
  <div>
    <subsription />
  </div>
</template>

<script>
import subsription from "../components/Subscription/index.vue";
export default {
  components: {
    subsription,
  },
};
</script>

<style></style>
